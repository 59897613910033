import React, { useEffect, useRef } from "react";

function WorkFlow() {
  const workflowRef = useRef(null);

  useEffect(() => {
    const cardObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
            const arrow = entry.target.nextElementSibling;
            console.log(arrow)
            if (arrow) {
              arrow.classList.add("visible-arrow");
            }
          }
        });
      },
      { threshold: 0.95 } // Trigger when 80% of the card is visible
    );

    const arrowObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // entry.target.classList.add("visible-arrow");
          }
        });
      },
      { threshold: 0.95 } // Trigger when 100% of the card is visible
    );

    const cards = workflowRef.current.querySelectorAll(".workflow-step");
    cards.forEach((card) => cardObserver.observe(card));

    const arrows = workflowRef.current.querySelectorAll(".workflow-arrow");
    arrows.forEach((arrow) => arrowObserver.observe(arrow));

    return () => {
      cardObserver.disconnect();
      arrowObserver.disconnect();
    };
  }, []);

  return (
    <div
      className="workflow-section mx-auto mb-10 pt-20 py-12 bg-gray-50 shadow-lg rounded-lg"
      ref={workflowRef}
    >
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-center text-dark-theme-green mb-8 lg:mb-12 font-poppins tracking-wide">
        OUR WORKING PROCESS
      </h1>
      <div className="workflow-steps grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8 items-center relative px-4 sm:px-8">
        {["Step 1", "Step 2", "Step 3", "Step 4"].map((step, index) => (
          <React.Fragment key={index}>
            <div
              className="workflow-step text-center group bg-white p-4 sm:p-6 rounded-lg shadow-md opacity-0 transform translate-y-16 transition-all duration-[1.2s] ease-in-out"
            >
              <div className="step-icon p-6 sm:p-8 rounded-full mx-auto mb-4">
                <span className="text-3xl sm:text-4xl lg:text-5xl">{["📄", "📅", "🏢", "✅"][index]}</span>
              </div>
              <h3 className="text-lg sm:text-xl font-bold text-dark-theme-green font-poppins">
                {step}
              </h3>
              <p className="text-xs sm:text-sm text-gray-600 mt-2 group-hover:text-gray-800">
                {[
                  "Document preparation and compliance assistance by expert professionals.",
                  "Scheduling appointments and assigning professionals to clients at nearby outlets.",
                  "Seamless handover of prepared documents for registration services at the registrar office.",
                  "Post-registration verification and delivery of registered documents to clients.",
                ][index]}
              </p>
            </div>
            {/* Arrow */}
            {index < 3 && (
              <div
                className="workflow-arrow hidden sm:hidden lg:block top-1/2 transform -translate-y-1/2 opacity-0 transition-opacity duration-500"
              >
                <span className="text-xl sm:text-2xl lg:text-3xl text-gray-400">➡️</span>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );  
}

export default WorkFlow;
