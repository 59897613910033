import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Button from 'elements/Button';
import Lottie from 'lottie-react';
import animationData from '../json/banner';
import { useNavigate } from 'react-router-dom';


export default function Hero() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [placeholderText, setPlaceholderText] = useState('Search through all our services');
  const [services, setServices] = useState([]);
  const placeholderOptions = [
    'Search through all our services',
    'Try "service"',
    'Find your best solution',
  ];
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
    let index = 0;
    let charIndex = 0;
    let currentText = '';
  
    const typeText = () => {
      if (charIndex < placeholderOptions[index].length) {
        currentText += placeholderOptions[index][charIndex];
        setPlaceholderText(currentText);
        charIndex += 1;
        setTimeout(typeText, 100);
      } else {
        setTimeout(() => {
          index = (index + 1) % placeholderOptions.length;
          currentText = '';
          charIndex = 0;
          setTimeout(typeText, 100);
        }, 2000);
      }
    };
  
    typeText();
  
    return () => clearTimeout(typeText);
  }, []);

  const fetchServices = async () => {
    const response = await axios.post('/services/get_all_services', {});
    setServices(response.data.responseData);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length > 0) {
      const results = services.filter((service) =>
        service.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredServices(results);
    } else {
      setFilteredServices([]);
    }
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData
  };

  return (
    <div
      className="hero w-full flex flex-col md:flex-row-reverse items-center"
      style={{
        paddingTop: "150px",
      }}
    >
      {/* Animation Section */}
      <Lottie 
        animationData={defaultOptions.animationData} 
        loop={defaultOptions.loop} 
        autoplay={defaultOptions.autoplay}
        style={{ overflow: "none" }}
        height={300} // Adjust size for smaller screens
        width={300}
      />
  
      {/* Text and Search Section */}
      <div className="text-center w-full max-w-4xl px-4">
        {/* Main Heading */}
        <h1 className="text-5xl sm:text-5xl text-white font-bold leading-tight mb-5 animate-slide-in">
          We Simplify Your
          <br />
          Entrepreneurial Journey
        </h1>
  
        {/* Subheading */}
        <p className="font-light text-lg sm:text-xl text-gray-300 leading-relaxed mb-8">
          Enabling entrepreneurs to stay legally compliant.
        </p>
  
        {/* Search Component */}
        <div className="relative w-full max-w-2xl mx-auto">
          <input
            type="text"
            className="w-full px-4 py-3 text-base text-gray-700 bg-white border border-gray-300 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-theme-green"
            placeholder={placeholderText}
            value={searchTerm}
            onChange={handleSearch}
          />
          <button
            className="absolute right-3 top-1/2 transform -translate-y-[115%] bg-theme-green text-white px-3 py-2 rounded-full"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-4.35-4.35M15 11a4 4 0 10-8 0 4 4 0 008 0z"
              />
            </svg>
          </button>
          {/* Dropdown for showing filtered services */}
          {filteredServices.length > 0 && (
            <ul className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-20">
              {filteredServices.map((service, index) => (
                <li
                  key={index}
                  className="px-4 py-3 text-gray-700 hover:bg-gray-100 cursor-pointer border-b last:border-none"
                  onClick={() => navigate(`/service/${service._id}`)}
                >
                  {service.name}
                </li>
              ))}
            </ul>
          )}
  
          {/* Suggested Services */}
          <div className="flex justify-center gap-2 mt-4 flex-wrap">
            {services.filter(x => x.name == "Business Registration" || x.name == "GST Compliance" || x.name == "Income Tax" || x.name == "Annual Compliance")
            .map((service, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-gray-200 text-black text-sm cursor-pointer hover:bg-dark-theme-green hover:text-white transition duration-200"
                style={{
                  border: "1px solid black",
                  borderRadius: "10px",
                }}
                onClick={() => navigate(`/service/${service._id}`)}
              >
                {(service.name).charAt(0).toUpperCase() + (service.name).slice(1).toLowerCase()}
              </span>
            ))}
          </div>
        </div>
  
        {/* CTA Button */}
        <Button
          href="/services"
          type="link"
          className="w-max mx-auto flex mt-6 items-center px-10 py-4 text-white text-lg bg-theme-green rounded-lg shadow-2xl hover:bg-theme-green transition duration-200"
          style={{
            border: "2px solid white",
            borderRadius: "20px",
          }}
        >
          See Our services
          <svg
            className="ml-2 w-6 h-6 text-white animate-bounce-x"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
}  