import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "parts/Header";
import Footer from "parts/Footer";
import Button from "elements/Button";
import "./ServiceDetails.css";
import axios from '../../axiosConfig';
import "quill/dist/quill.snow.css";
import Quill from "quill";

const ServiceDetails = () => {
  const { id } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const quillRef = useRef();

  const fetchServiceDetails = async () => {
    try {
      const response = await axios.post(`/services/details/get/${id}`, {});
      if (response.data.status === 200 && response.data.responseData.length > 0) {
        const description = response.data.responseData[0].description || "";
        setServiceDetails(description);
      } else {
        setServiceDetails(
          "<h3>No details found. But you can still login and avail this service</h3>"
        );
      }
      setLoading(false);
    } catch (err) {
      setError("Failed to load service details. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    setServiceDetails(null);
    setLoading(true);
    setError(null);
    fetchServiceDetails();
  }, [id]);

  useEffect(() => {
    if (quillRef.current && serviceDetails) {
      const quill = new Quill(quillRef.current, {
        theme: "snow",
        readOnly: true,
        modules: {
          toolbar: false,
        },
      });
      quill.clipboard.dangerouslyPasteHTML(serviceDetails);
    }
  }, [serviceDetails]);

  if (loading) {
    return (
      <div className="loading-wrapper">
        <div className="spinner"></div>
        <p>Loading service details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-wrapper">
        <h2>Error</h2>
        <p>{error}</p>
        <Button type="button" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="service-details-container">
        <div className="scrollable-content">
          <div className="quill-container" ref={quillRef}></div>
        </div>
        <div className="right-form">
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-theme-green mb-4 animate-pulse font-poppins">
          Get Started
        </h1>
        <p className="text-lg text-gray-600 mb-8 text-center">
          Fill out the login form below to begin.
        </p>
        <Button
          className="w-full py-3 mt-6 bg-gradient-to-r from-green-400 to-green-600 text-white text-xl rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
          type="button"
          onClick={()=>window.location = '/login'}
        >
          Login
        </Button>

        {/* Sign Up Section */}
        <div className="mt-6 text-center text-gray-600">
          <span>Don't have an account? </span>
          <Link to="/discuss-project" className="text-green-500 hover:underline font-semibold">
            Sign up
          </Link>
        </div>
      </div>
    </div>
      <Footer />
    </>
  );
};

export default ServiceDetails;
