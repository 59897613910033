import React from "react";

const onOkay = () => {
    const data = JSON.parse(window.sessionStorage.getItem('authState'));
    if (data.user.role === 'Client') window.location = '/client';
    if (data.user.role === 'Associate') window.location = '/home';
    if (data.user.role === 'Outlet') window.location = '/home';
}

const WelcomeComponent = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-br from-gray-100 to-gray-300">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md text-center">
        {/* Heading */}
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
          Welcome to <span className="text-theme-green">Ecregi</span>
        </h1>
        
        {/* Subtext */}
        <p className="text-gray-600 text-lg leading-relaxed mb-6">
          Thank you for joining us! We’re excited to have you on board.  
          Our team is working hard to roll out the full set of features 
          very soon. Stay tuned and hang in there!
        </p>
        
        {/* Logout Button */}
        <button
          onClick={onOkay}
          className="mt-4 px-6 py-2 text-lg font-medium text-white bg-theme-green rounded-full shadow-md hover:bg-dark-theme-green transition duration-300"
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default WelcomeComponent;
