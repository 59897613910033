import { useLottie, useLottieInteractivity } from "lottie-react";
import robotAnimation from "../../json/robotAnimation";

const options = {
  animationData: robotAnimation,
  height:"30px", 
  width:"30px"
};

const style = {
  height: 400,
};

const Example = () => {
  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "scroll",
    actions: [
      {
        visibility: [0, 0.2],
        type: "stop",
        frames: [0],
      },
      {
        visibility: [0.2, 0.45],
        type: "seek",
        frames: [0, 45],
      },
      {
        visibility: [0.45, 1.0],
        type: "loop",
        frames: [45, 60],
      },
    ],
  });

  return Animation;
};

export default Example;