import React, { useState } from 'react';
import axios from '../axiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'elements/Button';
import Form from 'elements/Form';
import Header from 'parts/Header';
import Footer from 'parts/Footer';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/api/auth/forgot-password', { email });
      if (response.data.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <section>
      <div className="relative flex items-center justify-center mt-20">
        
        {/* Main content container */}
        <div className="relative z-10 bg-white p-10 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-theme-green mb-6 text-center">
            Forgot Password
          </h2>
          <p className="text-gray-600 text-center mb-8">
            Enter your email to receive a password reset link.
          </p>

          {/* Email input field */}
          <Form
            id="email"
            name="email"
            type="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleChange}
            className="w-full py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 shadow-sm"
          />

          {/* Submit button with hover effect */}
          <Button
            onClick={handleSubmit}
            className="w-full py-3 mt-6 bg-theme-green text-white text-xl rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            Send Reset Link
          </Button>
        </div>
      </div>
      </section>

      <Footer />

      {/* Toast notifications */}
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
