// AuthContext.js
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-parens */

import React, { createContext, Component } from 'react';
import axios from '../axiosConfig';

// Create AuthContext
const AuthContext = createContext();

// Create AuthProvider component
class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...JSON.parse(window.sessionStorage.getItem('authState')) || {}, // Load state from local storage
      token: window.sessionStorage.getItem('token') || '',
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  setProfile = async () => {
    try {
      const response = await axios.post('/api/auth/profile', {}, {
        headers: {
          Authorization: `Bearer ${this.state.token}`, // Include token in request headers
        },
      });
      if (response.data.status === 200) {
        const newState = { token: this.state.token, user: response.data.responseData };
        window.sessionStorage.setItem('authState', JSON.stringify(newState)); // Save state in local storage
        this.setState(newState);
        if (response.data.responseData.role === 'Admin') {
          window.location = '/admin';
          return
        }
        window.location = '/welcome';
        return
      }
    } catch (error) {
      console.log('Authentication failed:', error);
    }
  }

  render() {
    const contextValue = {
      ...this.state,
      setProfile: this.setProfile, // Include setProfile in context value
      setToken: token => this.setState({ token }), // Function to update token state
    };
    return (
      <AuthContext.Provider value={{ ...contextValue }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export { AuthContext, AuthProvider };
