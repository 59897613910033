import React, { useEffect, useState, useRef } from 'react';
import axios from '../../../../axiosConfig';
import { Box, Typography, Button, MenuItem, FormControl, Select, InputLabel} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';


const HtmlEditor = () => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [editorData, setEditorData] = useState('');

  const editorRef = useRef(null);
  const quillRef = useRef(null);

  const handleSubmit = async () => {
    if (selectedSubcategory == "") {
      toast.error("Please select your service and subcategory");
      return;
    } 
    try {
      await axios.post("/services/details/add", { subcategoryId: selectedSubcategory._id, description: editorData });
      toast.success("Content saved successfully!");
    } catch (error) {
      console.error("Failed to save content:", error);
    }
  };

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
    setSelectedSubcategory(""); // Reset subcategory when service changes
  };

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
    getDetails(event.target.value);
  };

  const getAllServices = async() => {
    const response = await axios.post('/services/get_all_services', {});
    let data = response?.data?.responseData?.filter((item) => item.status === "Active");
    setServices(data);
  }

  const getDetails = async (val) => {
    try {
      const response = await axios.post(`/services/details/get/${val?._id}`, {});
      if (response.data.status === 200 && response.data.responseData.length > 0) {
        const description = response.data.responseData[0].description || "";
        setEditorData(description); // Update the editor data
      } else {
        setEditorData("No details found."); // Clear the editor if no data is found
        console.log(response.data.message || "No details found.");
      }
    } catch (error) {
      console.error("Failed to fetch content:", error);
      toast.error("Error fetching content.");
    }
  };
  

  const toolbarOptions = [
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  
    [{ 'color': ["black", "white", "green", "red", "blue", "yellow", "violet", "orange"] }, { 'background': ["black", "white", "green", "red", "blue", "yellow", "violet", "orange"] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];

  React.useEffect(() => {
    if (!quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: toolbarOptions,
        },
      });
  
      // Listen for text changes
      quillRef.current.on("text-change", () => {
        const html = editorRef.current.querySelector(".ql-editor").innerHTML;
        setEditorData(html);
      });
    }
  }, []);
  
  React.useEffect(() => {
    if (quillRef.current && quillRef.current.root.innerHTML !== editorData) {
      const cursorPosition = quillRef.current.getSelection();
      quillRef.current.clipboard.dangerouslyPasteHTML(editorData);
      if (cursorPosition) {
        quillRef.current.setSelection(cursorPosition);
      }
    }
  }, [editorData]);
  


  useEffect(() => {
    getAllServices()
  },[]);
  return (
    <Box p={3}>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center", background:"white", padding:"10px", borderRadius:"5px", justifyContent:"space-around" }}>
      <Typography variant="h3" gutterBottom style={{color:"black"}}>
        Service Details Editor
      </Typography>
        {/* Service Dropdown */}
        <FormControl style={{ minWidth: 200 }}>
          <InputLabel id="service-select-label">Service</InputLabel>
          <Select
            labelId="service-select-label"
            value={selectedService}
            onChange={handleServiceChange}
          >
            {services.map((service) => (
              <MenuItem key={service.name} value={service.name}>
                {service.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Subcategory Dropdown */}
        <FormControl style={{ minWidth: 200 }} disabled={!selectedService}>
          <InputLabel id="subcategory-select-label">Subcategory</InputLabel>
          <Select
            labelId="subcategory-select-label"
            value={selectedSubcategory?.name}
            onChange={handleSubcategoryChange}
          >
            {services
              .find((service) => service.name === selectedService)
              ?.subCategories.map((subcategory) => (
                <MenuItem key={subcategory.name} value={subcategory}>
                  {subcategory.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button onClick={handleSubmit} style={{background:"white", border:"1px solid"}}>
          Save Content
        </Button>
      </div>
      <div
        style={{
          background:"white",
          height: "550px",
          color:"black"
        }}
      >
        <div ref={editorRef} style={{height:"500px"}}/>
      </div>
      <ToastContainer />
    </Box>
  );
};

export default HtmlEditor;

