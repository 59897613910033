/* eslint-disable react/prop-types */
import React from "react";
import RobotAnimation from '../elements/Mission/mission';

export default function MissionVision() {
  return (
    <>
    <section className="mission-vision-section">
      <h1 className="text-5xl text-dark-theme-green text-center font-bold">Mission & Vision</h1>
        <RobotAnimation />
      <div className="card-container">
        <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <h2>🌟 Our Vision</h2>
              </div>
              <div className="card-back">
                <p>We aim to create a unified platform connecting legal professionals, offering seamless solutions for compliance and document drafting</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-inner">
              <div className="card-front">
                <h2>🚀 Our Mission</h2>
              </div>
              <div className="card-back">
                <ul>
                    <li>- Unite legal expertise</li>
                    <li>- Simplify client access to services</li>
                    <li>- Maintain quality and foster trust</li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    </section>
    </>
  );
}
