import React, { useContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import Header from 'parts/Header';
import Footer from 'parts/Footer';
import Form from 'elements/Form';
import Button from 'elements/Button';
import { ToastContainer, toast } from 'react-toastify';
import axios from '../axiosConfig';
import { AuthContext } from '../components/AuthContext';
import { Link } from 'react-router-dom';

const LoginPage = () => {
  const { setProfile, setToken, user } = useContext(AuthContext);
  const [data, setData] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setData({
      username: '',
      password: '',
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/api/auth/login', {
        username: data.username,
        password: data.password,
      });
      if (response.data.status === 200) {
        toast.success(response.data.message);
        window.sessionStorage.setItem('token', response.data.responseData);
        // console.log('token = ', response.data.responseData);
        await setToken(response.data.responseData);
        await setProfile();
        // console.log('user = ', user);
        resetForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      window.sessionStorage.removeItem('token');
      if (error.response && error.response.data) {
        console.log(error.response.data);
      } else {
        console.log('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <>
      <Header />
      {/* Animated gradient background */}
      <div className="relative flex items-center justify-center mt-20 px-4">
      {/* Main content container */}
      <section className="relative z-10 flex flex-col items-center p-8 bg-white rounded-xl shadow-lg max-w-md mx-auto w-full">
        {/* Welcome message with subtle animation */}
        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-theme-green mb-4 animate-pulse font-poppins">
          Welcome to ECREGI
        </h1>
        <p className="text-lg text-gray-600 mb-8 text-center">
          Please fill out the form below to log in.
        </p>

        {/* Form fields */}
        <div className="space-y-4 w-full">
          <Form
            id="username"
            name="username"
            type="text"
            value={data.username}
            placeholder="Username"
            onChange={onChange}
            className="w-full py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 shadow-sm"
          />
          <Form
            id="password"
            name="password"
            type="password"
            value={data.password}
            placeholder="Password"
            onChange={onChange}
            className="w-full py-2 px-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 shadow-sm"
          />
        </div>

        {/* Forgot Password link */}
        <Link to="/forgot-password" className="text-sm text-green-500 hover:underline mt-4">
          Forgot Password?
        </Link>

        {/* Login button with hover effect */}
        <Button
          className="w-full py-3 mt-6 bg-gradient-to-r from-green-400 to-green-600 text-white text-xl rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105"
          type="button"
          onClick={handleSubmit}
        >
          Login
        </Button>

        {/* Sign Up Section */}
        <div className="mt-6 text-center text-gray-600">
          <span>Don't have an account? </span>
          <Link to="/discuss-project" className="text-green-500 hover:underline font-semibold">
            Sign up
          </Link>
        </div>
      </section>
    </div>

      {/* Footer */}
      <Footer />

      {/* Toast container for notifications */}
      <ToastContainer />
    </>
  );
};

export default LoginPage;
