/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Button from 'elements/Button';

export default function BrandIcon() {
  return (
    <Button className="" type="link" href="/">
      <p className="brand-icon">
        <span className="brand-letter text-dark-theme-green">E</span>
        <span className="brand-letter text-dark-theme-green">C</span>
        <span className="brand-letter text-light-theme-green">R</span>
        <span className="brand-letter text-light-theme-green">E</span>
        <span className="brand-letter text-light-theme-green">G</span>
        <span className="brand-letter text-light-theme-green">I</span>
      </p>
    </Button>
  );
}
