import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  IconButton,
  Menu,
  Stack,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemButton,
} from "@mui/material";
import {
  LightModeOutlined,
  DarkModeOutlined,
  NotificationsOutlined,
  SettingsOutlined,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../../../../theme";
import { useNavigate } from "react-router-dom";
import axios from "../../../../axiosConfig";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); 
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(services[0]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.post("/services/get_all_services", {});
      setServices(
        response.data?.responseData?.filter((item) => item.status === "Active")
      );
    } catch (error) {
      console.log(error);
    }
  };


  const iconColor =
    theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[800];

  const handleServicesClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(!menuOpen);
  };

  const handleServicesClose = () => {
    setAnchorEl(null);
    setMenuOpen(!menuOpen);
  };

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  const handleSubCategoryClick = (service, subcategory) => {
    navigate(`/client/apply/${JSON.stringify(service.name)}/${JSON.stringify(subcategory)}?additionalData=${encodeURIComponent(JSON.stringify(service.subCategories))}`);
    handleServicesClose();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      sx={{
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* LEFT SIDE - ALL SERVICES DROPDOWN */}
      <Box>
        <Typography
          aria-controls="services-menu"
          aria-haspopup="true"
          onClick={handleServicesClick}
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "white",
            cursor: "pointer",
            display: "inline-flex",
            alignItems: "center",
            padding: "10px 20px",
            borderRadius: "15px",
            textTransform:"uppercase",
            letterSpacing: "0.05em",
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
            transition: "all 0.3s ease",
            border: "2px solid",
          }}
        >
          All Services
          {menuOpen ? (
            <ArrowDropUp sx={{ fontSize: "2rem", transition: "0.3s" }} />
          ) : (
            <ArrowDropDown sx={{ fontSize: "2rem", transition: "0.3s" }} />
          )}
        </Typography>

        <Menu
          id="services-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleServicesClose}
          sx={{
            mt: 2,
            "& .MuiPaper-root": {
              display: "flex",
              p: 2,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
              width: "600px",
              transition: "all 0.4s ease",
            },
          }}
        >
          <Grid container spacing={2}>
            {/* Left Section - Service List */}
            <Grid item xs={5}>
              <List>
                {services &&
                  services.map((service) => (
                    <ListItemButton
                      key={service.name}
                      onMouseEnter={() => handleServiceSelect(service)}
                      selected={selectedService?.name === service.name}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        px: 2,
                      }}
                    >
                      <ListItemText
                        primary={service.name}
                        sx={{
                          fontWeight: "bold",
                        }}
                      />
                      <ArrowForward />
                    </ListItemButton>
                  ))}
              </List>
            </Grid>

            {/* Right Section - Subcategory List */}
            <Grid item xs={7}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <List style={{width:"100%"}}>
                  {selectedService?.subCategories.map((subcategory) => (
                    <ListItem
                      key={subcategory._id}
                      button
                      onClick={() =>
                        handleSubCategoryClick(selectedService, subcategory.name)
                      }
                      sx={{ px: 2 }}
                    >
                      <ListItemText primary={subcategory.name} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Menu>
      </Box>

      {/* RIGHT SIDE - ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined sx={{ color: iconColor, fontSize: "1.5rem" }} />
          ) : (
            <LightModeOutlined sx={{ color: iconColor, fontSize: "1.5rem" }} />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlined sx={{ color: iconColor, fontSize: "1.5rem" }} />
        </IconButton>
        <IconButton>
          <SettingsOutlined sx={{ color: iconColor, fontSize: "1.5rem" }} />
        </IconButton>
        <IconButton
          onClick={() => {
            window.sessionStorage.removeItem("authState");
            window.sessionStorage.removeItem("token");
            window.location = "/";
          }}
        >
          <Stack direction="column" spacing={1} alignItems="center">
            <LogoutIcon sx={{ color: iconColor }} />
            <Typography variant="body2" sx={{ color: 'black', fontWeight: 'bold' }}>
              Logout
            </Typography>
          </Stack>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
