import React, { useState, useContext, useEffect } from 'react';

import { Fade } from 'react-awesome-reveal';

import Button from 'elements/Button';
import BrandIcon from 'parts/BrandIcon';

import { AuthContext } from 'components/AuthContext';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Link } from 'react-router-dom';
import axios from '../axiosConfig'

export default function Header() {
  const [isCollapse, setIsCollapse] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const path = window.location.pathname;
  const { user } = useContext(AuthContext);
  const [services, setServices] = useState([]);

  const fetchServices = async () => {
    try {
      const response = await axios.post("/services/get_all_services", {});
      const data = response.data.responseData.filter((item) => item.status === "Active");
      setServices(data || []);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  // Split services into top categories and remaining
  const topCategories = services.filter(service => service.name === "Deed Drafting" ||  service.name === "BUSINESS REGISTRATION" || service.name === "Income Tax");
  const otherServices = services.filter(service => service.name !== "Deed Drafting" && service.name !== "BUSINESS REGISTRATION" && service.name !== "Income Tax");
  return (
    <header className="header bg-white rounded-md">
      <Fade>
        <div className="flex justify-between px-4 lg:px-0">
          <BrandIcon />

          <button
            className="block text-theme-blue lg:hidden focus:outline-none"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <svg
              className="w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                className={`${isCollapse ? 'hidden' : 'block'}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
              <path
                className={`${!isCollapse ? 'hidden' : 'block'}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <ul className="hidden text-theme-blue tracking-widest items-center lg:flex flex-row mt-0 relative z-[10]">

        {/* Top Categories */}
        {topCategories.map((service, index) => (
          <li
            key={index}
            className="relative group"
            onMouseEnter={() => setActiveCategory(service.name)}
            onMouseLeave={() => setActiveCategory(null)}
          >
            <button className="text-base font-bold text-green-600 px-5 py-2 rounded-md hover:bg-green-600 hover:text-white transition-all duration-200 flex items-center justify-between gap-2">
              {/* Capitalize and Display Category Name */}
              {(service.name).charAt(0).toUpperCase() + (service.name).slice(1).toLowerCase()}
              {/* Dropdown Arrow */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 transform group-hover:rotate-180 transition-transform duration-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {/* Dropdown for Subcategories */}
            <div
              className={`absolute top-full left-0 bg-gray-50 border border-gray-300 shadow-lg py-2 rounded-md z-[9999] ${
                activeCategory === service.name
                  ? "opacity-100 scale-100"
                  : "opacity-0 scale-95 pointer-events-none"
              }`}
              style={{
                width: service.subCategories.length > 10 ? "800px" : "300px", // Adjust width based on count
              }}
            >
                <div
                  className={`grid ${
                    service.subCategories.length > 10 ? "grid-cols-3 gap-4" : "grid-cols-1"
                  } px-4 py-2`}
                >
                {service.subCategories.map((subcategory, subIndex) => (
                  <Link
                    key={subIndex}
                    to={`/details/${subcategory.name}/${subcategory._id}`}
                    className="flex items-center px-2 py-2 text-gray-700 hover:bg-gradient-to-r hover:from-green-300 hover:to-green-700 hover:text-white transition duration-200 ease-in-out"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 12h14m-7-7l7 7-7 7"
                      />
                    </svg>
                    {/* Subcategory Text */}
                    <span className="text-lg font-medium">
                      {subcategory.name.charAt(0).toUpperCase() + subcategory.name.slice(1).toLowerCase()}
                    </span>
                  </Link>
                ))}
              </div>
            </div>

          </li>
        ))}


        {otherServices.length > 0 && (
          <li
            className="relative group"
            onMouseEnter={() => setActiveCategory("More Services")}
            onMouseLeave={() => setActiveCategory(null)}
          >
            <button className="flex items-center text-base font-bold text-green-600 px-5 py-2 rounded-md hover:bg-green-600 hover:text-white transition-all justify-between gap-2 duration-200">
              {"More Services"}
              {/* Dropdown Arrow */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 transform group-hover:rotate-180 transition-transform duration-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
            {/* Dropdown for Other Services */}
            <div
              className={`absolute top-full left-0 bg-gray-50 border border-gray-300 shadow-lg py-2 rounded-md min-w-[250px] w-[300px] z-[9999] ${
                activeCategory === "More Services" ? "opacity-100 scale-100" : "opacity-0 scale-95 pointer-events-none"
              }`}
            >
              {otherServices.map((service, index) => (
                <Link
                  key={index}
                  to={`/service/${service._id}`}
                  className="flex items-center px-6 py-2 text-gray-700 hover:bg-gradient-to-r  hover:from-green-300 hover:to-green-700 hover:text-white rounded transition duration-200 ease-in-out"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14m-7-7l7 7-7 7" />
                  </svg>
                  <span className="text-lg font-medium">{service.name.charAt(0).toUpperCase() + service.name.slice(1).toLowerCase()}</span>
                </Link>
              ))}
            </div>
          </li>
        )}

          <li>
            <Button
              className="text-lg mx-auto ml-3 px-6 py-2 bg-white text-green-600 rounded-full border-2 border-green-600 hover:bg-green-600 hover:text-white hover:border-white transition duration-200"
              type="link"
              href="/discuss-project"
              isExternal={false}
            >
              Join Us
            </Button>
          </li>
          <li className="py-2 lg:py-0">
            {user ? (
              <div className="px-5">
                <Link to={user.role === 'Admin' ? '/admin' : '/client'}>
                  <PersonOutlinedIcon />
                </Link>
              </div>
            ) : (
              <Button
                className={`${path === '/login' ? 'active-link' : ''} text-base font-bold text-green-600 px-5 py-2 hover:text-white hover:bg-green-600 rounded ease-in-out  transition-all duration-200`}
                type="link"
                href="/login"
                isExternal={false}
              >
                Login
              </Button>
            )}
          </li>
        </ul>
      </Fade>
      <div className={`lg:hidden ${isCollapse ? 'block' : 'hidden'} transition-all duration-300 ease-in-out`}>
        <ul className="flex flex-col gap-3 bg-white border border-gray-200 rounded-lg shadow-md p-4 items-center">
          {/* Home Link */}
          <li>
            <Button
              className={`${path === '/' ? 'font-bold text-theme-green underline' : 'text-gray-700'} 
              text-lg px-5 py-2 rounded-md hover:text-dark-theme-green hover:bg-gray-100 transition-all`}
              type="link"
              href="/"
              isExternal={false}
            >
              Home
            </Button>
          </li>

          {/* Join Us Button */}
          <li>
            <Button
              className="text-lg mx-auto px-6 py-3 bg-theme-green text-white rounded-full shadow-md 
              hover:bg-dark-theme-green hover:shadow-lg transition-all duration-200"
              type="link"
              href="/discuss-project"
              isExternal={false}
            >
              Join Us
            </Button>
          </li>

          {/* Login/Profile Section */}
          <li>
            {user ? (
              <div className="flex items-center gap-3 px-5">
                <Link
                  to={user.role === 'Admin' ? '/admin' : '/client'}
                  className="flex items-center gap-2 text-theme-green hover:text-dark-theme-green transition"
                >
                  <PersonOutlinedIcon className="text-2xl" />
                  <span className="text-lg font-medium">Profile</span>
                </Link>
              </div>
            ) : (
              <Button
                className={`${path === '/login' ? 'font-bold text-green-600' : 'text-gray-700'} 
                text-lg px-5 py-2 hover:text-green-600 hover:underline transition`}
                type="link"
                href="/login"
                isExternal={false}
              >
                Login
              </Button>
            )}
          </li>
        </ul>
      </div>


    </header>
  );
}
