import { Route, Routes } from 'react-router-dom';

import DiscussProjectPage from 'pages/DiscussProjectPage';
import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import NotFoundPage from 'pages/NotFoundPage';
import ProjectDetailPage from 'pages/ProjectDetailPage';
import ProjectPage from 'pages/ProjectPage';
// import TeamPage from 'pages/TeamPage';
import withAuthGuard from './components/GuardedRoutes';
import Unauthorized from './components/Unauthorized';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import WelcomeComponent from 'components/Welcome';

import Admin from 'pages/Admin/Admin';
import Client from 'pages/Client/Client';
import Associate from 'pages/Associate/Associate';
import ServiceDetails from 'pages/ServiceDetails/ServiceDetails'


import 'assets/css/styles.css';

function App() {
  
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/services" element={<ProjectPage />} />
      <Route exact path="/service/:id" element={<ProjectDetailPage />} />
      {/* <Route exact path="/team" element={<TeamPage />} /> */}
      <Route exact path="/discuss-project" element={<DiscussProjectPage />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="/client/*" element={withAuthGuard(Client, ['Client'])} />
      <Route path="/admin/*" element={withAuthGuard(Admin, ['Admin'])} />
      <Route path="/home/*" element={withAuthGuard(Associate, ['Associate', "Outlet"])} />
      <Route exact path="/unauthorized" element={<Unauthorized />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/welcome" element={<WelcomeComponent />} />
      <Route exact path="/details/:serviceTitle/:id" element={<ServiceDetails />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
