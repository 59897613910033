/* eslint-disable react/jsx-filename-extension */
import React, {useState, useEffect} from 'react';
import axios from '../axiosConfig';
import BrandIcon from 'parts/BrandIcon';
import Button from 'elements/Button';

export default function Footer() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch services and subcategories
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.post('/services/get_all_services', {});
        setServices(
          response.data?.responseData?.filter((item) => item.status === "Active")
        );
      } catch (err) {
        setError("Failed to load services");
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  return (
    <div className="bg-gray-50 border-t border-gray-400 py-8">
      <div className="container mx-auto px-4 lg:flex lg:justify-between flex-col">
        {/* Services Section */}
        <div className="w-full mb-8 lg:mb-2">
          {services.map((service) => (
            <div key={service._id} className="mb-6">
              <h2 className="text-lg font-bold text-theme-green">{service.name}</h2>
              <hr className="border-t-2 border-gray-300 my-2" />
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 cursor-pointer">
                {service.subCategories.map((subcategory) => (
                  <p key={subcategory._id} className="text-gray-600" onClick={()=>window.location = `/details/${subcategory.name}/${subcategory._id}`}>
                    {subcategory.name}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="w-full flex flex-row flex-grow px-4 justify-evenly mt-10">
          {/* Brand Section */}
          <div className="mb-8 lg:mb-0">
            <BrandIcon />
            <p className="text-gray-400 font-light mt-4">
              We Simplify Your
              <br />
              Entrepreneurial Journey
            </p>
          </div>
  
          {/* Office and Social Section */}
          <div className='flex flex-row justify-around flex-grow'>
            {/* Office */}
            <div className="mb-6">
              <h2 className="text-lg font-bold text-theme-green">Office</h2>
              <p className="text-gray-400">
                Sathy Road, Ganapathy,
                <br />
                Coimbatore - 641 006.
              </p>
            </div>
  
            {/* Social */}
            <div className='flex flex-col'>
              <h2 className="text-lg font-bold text-theme-green">Social</h2>
              <Button
                href="https://www.instagram.com/"
                type="link"
                target="_blank"
                className="text-gray-400 hover:underline block"
                isExternal
              >
                Instagram
              </Button>
              <Button
                href="https://www.linkedin.com/"
                type="link"
                target="_blank"
                className="text-gray-400 hover:underline block"
                isExternal
              >
                LinkedIn
              </Button>
              <Button
                href="https://github.com/"
                type="link"
                target="_blank"
                className="text-gray-400 hover:underline block"
                isExternal
              >
                Github
              </Button>
            </div>
          </div>
        </div>
      </div>
  
      {/* Bottom Section */}
      <div className="text-center mt-8">
        <p className="text-gray-400 font-light">
          Copyright 2024 - All rights reserved - ECDraft consultancy pvt ltd.
        </p>
        <p className="text-gray-400 font-light">
          Made with ❤️ by&nbsp;
          <Button
            href="https://github.com/KPMAssociates"
            type="link"
            target="_blank"
            className="text-theme-green font-light"
            isExternal
          >
            ECREGI
          </Button>
        </p>
      </div>
    </div>
  );  
}