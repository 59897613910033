import React, { useContext } from 'react';

import { Fade } from 'react-awesome-reveal';
import Button from 'elements/Button';
import { AuthContext } from 'components/AuthContext';

export default function AllPortfolio({ data }) {
  const { user } = useContext(AuthContext);
  data = data.filter((item) => item.status === "Active");
  return (
    <Fade bottom>
      <section className="container mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 xl:gap-6 justify-items-center relative z-10 p-8 bg-gray-100 rounded-xl shadow-lg w-full">
          {data.map((item, index) => (
            <Fade bottom delay={500 * index} key={index}>
              <Button
                type="link"
                href={`/service/${item._id}`}
              >
                <div className="group rounded-xl shadow-md w-60 m-2 transform transition duration-300 hover:scale-105 portofolio-card">
                  <div className="relative">
                    <img
                      src={item.photo}
                      alt="Portfolio"
                      className="rounded-t-xl h-40 object-cover w-full"
                    />
                    <div className="absolute flex w-full h-full top-0 opacity-0 bg-black justify-center items-center rounded-t-xl rounded-b img-hover group-hover:opacity-70 transition-opacity duration-300">
                      <button className="focus:outline-none">
                        <svg
                          className="w-12 h-12 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="py-3">
                    <h2 className="text-green-600 text-center text-lg font-bold">
                      {item.name}
                    </h2>
                  </div>
                </div>
              </Button>
            </Fade>
          ))}
        </div>
      </section>
    </Fade>
  );  
}
