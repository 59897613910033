import React, { useContext } from 'react';
import { Fade } from 'react-awesome-reveal';
import NotFound from 'assets/images/NotFound.png';
import Button from 'elements/Button';
import { AuthContext } from 'components/AuthContext';

export default function PortfolioDetail({ data }) {
  const { user } = useContext(AuthContext);
  
  // If no service data, show a Not Found page
  if (data === null) {
    return (
      <section className="container mx-auto mt-20">
        <Fade bottom>
          <div className="flex flex-col w-full justify-center p-5" style={{border:"10px solid"}}>
            <div className="flex w-full justify-center">
              <img src={NotFound} alt="Not Found" className="sm:w-3/4 xl:w-5/12 mt-5" />
            </div>
            <h1 className="text-theme-blue text-2xl text-center mt-20">
              Service Not Found
            </h1>
            <div className="flex justify-center">
              <Button href={"/services"} type="link" className="flex w-30 h-10 px-5 mt-5 bg-theme-blue text-white items-center rounded transform transition duration-500 hover:bg-gray-900">
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  return (
    <section className="container mx-auto mt-20">
      <Fade bottom>
        <Button type="link" href={"/services"} className="flex w-40 h-8 text-lg items-center ml-6 sm:ml-20 mt-8 font-light text-gray-300 hover:underline">
          <svg className="w-5 h-5 text-gray-300 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
          See All Services
        </Button>
      </Fade>

      <div className="flex flex-col mt-8 justify-center p-5" style={{border:"10px solid"}} key={data._id}>
        <Fade bottom>
          <h1 className="text-5xl text-white text-center font-bold">{data.name}</h1>
        </Fade>

        {/* Image on the Left, Description on the Right */}
        <div className="flex flex-col sm:flex-row items-center sm:items-center mt-8">
          <div className="sm:w-1/3 flex justify-center sm:justify-end">
            <img src={data.photo} alt="Service" className="w-full sm:w-4/6 rounded-lg shadow-lg" />
          </div>
          <div className="sm:w-2/3 mt-6 sm:mt-0 sm:ml-10">
            <Fade bottom delay={300}>
              <p className="font-light indent-20 text-lg text-white text-justify">{data.description}</p>
            </Fade>
          </div>
        </div>

        {/* Subcategories - Service Responsibilities */}
        <div className="flex flex-col mx-8 sm:mx-16 xl:mx-28 mt-16">
          <h1 className="text-3xl text-white font-bold mb-5">Service SubCategories</h1>
          <div className="flex flex-wrap gap-4 ml-1">
            {data.subCategories.map((responsibility, index) => (
              <div
                key={index}
                className="px-6 py-3 text-white border border-white hover:bg-white hover:border-theme-green hover:text-theme-green rounded-full shadow-lg cursor-pointer"
                onClick={() => window.location = "/details/" + responsibility.name.toLowerCase().replace(/\s+/g, '-',) + `/${responsibility._id}`}
              >
                {responsibility.name}
              </div>
            ))}
          </div>
        </div>

        {/* Additional Information Grid */}
        {/* {user && (
          <Fade bottom delay={300}>
            <div className="flex flex-col mt-8 mx-8 sm:mx-16 xl:mx-28">
              <h1 className="text-3xl text-theme-blue font-bold mb-5">Additional Information</h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <div className="p-4 border rounded-lg shadow-md">
                  <h2 className="text-2xl text-theme-blue font-semibold mb-2">Timeline</h2>
                  <p className="text-lg text-gray-400">{data.timeline}</p>
                </div>

                <div className="p-4 border rounded-lg shadow-md">
                  <h2 className="text-2xl text-theme-blue font-semibold mb-2">Application Fee</h2>
                  <p className="text-lg text-gray-400">{data.applicationFee}</p>
                </div>

                <div className="p-4 border rounded-lg shadow-md">
                  <h2 className="text-2xl text-theme-blue font-semibold mb-2">Service Charge</h2>
                  <p className="text-lg text-gray-400">{data.serviceCharge}</p>
                </div>
              </div>
            </div>
          </Fade>
        )} */}
      </div>
    </section>
  );
}
