/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import withParams from '../components/withParams';
import Header from 'parts/Header';
import PortfolioDetail from 'parts/PortfolioDetail';
import Footer from 'parts/Footer';

import axios from '../axiosConfig';

class ProjectDetailPage extends Component {
  state = {
    detailData: null, // Initialize state to store the detail data
    loading: true,    // State to manage loading state
    error: null,      // State to manage errors
  };
  
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchDetailData();
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.params.id;
    const currentId = this.props.params.id;
  
    if (prevId !== currentId) {
      this.fetchDetailData();
    }
  }

  fetchDetailData = async () => {
    const { id } = this.props.params;
    try {
      const response = await axios.post(`/services/get_service/${id}`);
      this.setState({ detailData: response.data.responseData, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  

  render() {
    const { detailData, loading, error } = this.state;

    return (
      <>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        <Header {...this.props} />
        {!loading && !error && <PortfolioDetail data={detailData} />}
        <Footer />
      </>
    );
  }
}

export default withParams(ProjectDetailPage);
