/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import Header from 'parts/Header';
import Hero from 'parts/Hero';
// import Service from 'parts/Service';
import Portfolio from 'parts/Portfolio';
import Advantage from 'parts/Advantage';
import Testimonial from 'parts/Testimonial';
import MissionVision from 'parts/MissionVision';
import Workflow from 'parts/WorkFlow';
import Discuss from 'parts/Discuss';
import Footer from 'parts/Footer';

import Data from 'json/landingPage.json';
import axios from '../axiosConfig';

export default class LandingPage extends Component {

  state = {
    portfolioData: [], // Initialize state to store the portfolio data
    loading: true,     // State to manage loading state
    error: null,       // State to manage errors
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchPortfolioData();
  }

  fetchPortfolioData = async () => {
    try {
      const response = await axios.post('/services/get_all_services', {});
      this.setState({ portfolioData: response.data.responseData, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  render() {
    const { portfolioData} = this.state;
    return (
      <>
        <Header {...this.props} />
        <Hero {...this.props} />
        <Workflow />
        <Advantage data={Data.advantage} />
        {/* <Service data={Data.service} /> */}
        {portfolioData.length > 0 && <Portfolio data={portfolioData} />}
        <MissionVision />
        <Testimonial data={Data.testimonial} />
        <Discuss />
        <Footer />
      </>
    );
  }
}
